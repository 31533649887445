import './continue_splash.css';
import Button from '@mui/material/Button';

export const ContinueSplash = ({handleContinue}) => {
    return (
        <div className="splash-overlay">
            <div className="splash-content">
                <p>Welcome back!</p>
                <Button
                    variant="contained"
                    onClick={handleContinue}
                    sx={{
                        fontSize: '1.5rem', // Increase font size
                        fontWeight: 'bold', // Apply bold font weight
                        backgroundColor: '#439946', // Set background color to green
                        '&:hover': {
                        backgroundColor: '#306D32', // Darker green color on hover
                        },
                    }}
                    >
                    CONTINUE
                </Button>
            </div>
        </div>
    );
}