import'./instructions.css';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';

import playButtonImage from './play_button_example.png';
import searchImage from './search_example.png';
import guessImage from './guess_example.png';
import graphicalAbsract from './graphical_abstract.png';

export const InstructionsSplash = ({handleClickOutside}) => {
    return (
      <div className="instruct-splash-page" >
        < div className = "instruct-background" onClick = {handleClickOutside} > </div>

        <div className="instruct-splash-content">
            <button className='close-button' onClick={handleClickOutside}>
              <CloseIcon fontSize='medium'/>
            </button>
          <h1 className='instruct-heading'>How to Play...</h1>

          <div className="abstract-container">
            <img src={graphicalAbsract} alt="Graphical Abstract" />
          </div>
          <p className='instruct-paragraph'>
            Remember to turn up your volume!
          </p>

          {/* <nl className='instruct-list'>
              <li>Click to play the sound.</li>
              <li>Start typing in the search box.</li>
              <li>Select a phrase describing the sound.</li>
            </nl>
            <div className="image-container">
              <img src={playButtonImage} alt="Play Button" className="image play-image" />
              <img src={searchImage} alt="Search Box" className="image search-image" />
              <img src={guessImage} alt="Guess Example" className="image guess-image" />
            </div>
            <p className='note'>Some sounds have multiple answers! </p>
             */}
            <hr />

          <div className="guess-container">
            <p className='guess-subheading'>First Guess</p>
          </div>
          <nl className='instruct-list'>
              <li className='li-guess'>2x Sound Plays.</li>
            </nl>
          <div className="guess-container">
            <p className='guess-subheading'>Second Guess</p>
          </div>
          <nl className='instruct-list'>
              <li className='li-guess'>2x Sound Plays + Category.</li>
          </nl>

          <div className="guess-container guess-three">
            <p className='guess-subheading'>Third Guess</p>
          </div>


          <nl className='instruct-list'>
              <li className='li-guess'> Infinite Sound Plays + Category.</li>
          </nl>
          <p className='instruct-subheading'>Good Luck!</p>
          <hr />
          <div className="close-instructinos-container">
            <Button
              variant="contained"
              onClick={handleClickOutside}
              sx={{
                  fontSize: '1rem', // Increase font size
                  fontWeight: 'bold', // Apply bold font weight
                  backgroundColor: '#800122', // Set background color to green
                  '&:hover': {
                  backgroundColor: '#66011b', // Darker green color on hover
                  },
              }}
              >
              Close Instructions
            </Button>
          </div>
          <hr />
          <p className='instruct-paragraph'>A new sound is released every day at midnight!</p>
        </div>
      </div>

    );
  };