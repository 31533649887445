import React, { useState, useRef, useEffect } from "react";
import "./App.css";

//Files
import textfile from "./guess_list.txt";

//Functions
import { getIndex, getSoundName, checkCorrectAnswer, getDocument } from './useEffectFunctions.js';
import { addAnswer, dailyReset, clearStorage, nullGuesses } from "./localFunc.js";
import { updateTotalSolves } from "./updateFunc.js";


//Firebase
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db, storageRef  } from "./firebaseConfig.js";
import { ref, getDownloadURL } from "firebase/storage";

//Analytics
import { getAnalytics, logEvent } from "firebase/analytics";
import { app } from "./firebaseConfig.js";




//Materials
import {TopBar, GuessResultGrid, GuessResultBox} from './uimui.js';

//Splash Pages
import { InstructionsSplash } from './instructions/instructionsSplash.js';
import { ContinueSplash } from "./continue_splash/continue_splash.js";
import { StatsSplash } from "./stats_splash/stats.js";
import { Translate } from "@mui/icons-material";

// Initialize Firebase

function App() {
	const importAll = (context) => context.keys().map(context);

	//firebase
	const analytics = getAnalytics(app);
	

	const [selectedSoundName, setSelectedSoundName] = useState("");
	const [currentSearch, setCurrentSearch] = useState("");
	const audioRef = useRef(new Audio());
	const [guessList, setGuessList] = useState([]);

	//Guess boxes
	const [firstBoxColor, setFirstBoxColor] = useState('grey'); // Initialize as grey
	const [secondBoxColor, setSecondBoxColor] = useState('grey'); // Initialize as grey
	const [thirdBoxColor, setThirdBoxColor] = useState('grey'); // Initialize as grey
	const wrongGuessColor = '#B59410'; // Color for wrong guesses
	const correctGuessColor = '#457a25'; // Color for correct guesses
	const allWrongColor = '#761613'; // Color for all wrong guesses

	const [playCount, setPlayCount] = useState(0);
	const isSoundPlayable = playCount < 2;
	const playsLeft = 2 - playCount;
	//const setPlaysLeft;

	const [incorrectGuessCount, setIncorrectGuessCount] = useState(0);
  	const [disableGuess, setDisableGuess] = useState(false);
	const maxGuesses = 3;

	const [showAnswer, setShowAnswer] = useState(false);
  	const [answer, setAnswer] = useState("");
	const [gameOver, setGameOver] = useState(false);
	const [showStatsBox, setShowStatsBox] = useState(false);

	const [displayedCategory, setDisplayedCategory] = useState("");

	const remainingGuesses = maxGuesses - incorrectGuessCount;

	const [isButtonDisabled, setIsButtonDisabled] = useState(false);

	const [soundIndex, setSoundIndex] = useState(null);
	
	const [playerRank, setPlayerRank] = useState(0);

	const [soundDoc, setSoundDoc] = useState(null);

	//Splash Pages
	const [showInstructions, setShowInstructions] = useState(false);
	const [showSplash, setShowSplash] = useState(true);

	//Category State
	const [category, setCategory] = useState("");

	useEffect(() => {
		if (!localStorage.getItem("todayDate")) {
			setShowInstructions(true);
		}
		logEvent(analytics, 'game_started');
		//fetch text file
		fetch(textfile)
			.then((response) => response.text())
			.then((textContent) => {
				//convert text to array
				textContent = textContent.split("\n");
				//sort alphabetically
				textContent.sort();
				setGuessList(textContent);
			});
		
			//set up local storage
			if(localStorage.getItem("firstGuess")) {
				console.log("Hello traveller!");
			} else {
				nullGuesses();
			}
		
		dailyReset(); //reset local storage for the day	
		if (localStorage.getItem("soundPlayCount") === "false") {
			setPlayCount(parseInt(localStorage.getItem("soundPlayCount")));
		}
			
		getIndex().then((index) => {
			setSoundIndex(index);

			getSoundName(index).then((todaySound) => {
				
				todaySound = todaySound.split(".")[0];

				// Check if 'playedToday' is true in local storage
				if (localStorage.getItem("playedToday") === "true") {
					setShowSplash(true);
				} else {
					setShowSplash(false);
				}

				getDocument(todaySound).then((doc) => {
					setSoundDoc(doc);
					setCategory(doc.soundCategory);
					setSelectedSoundName(doc.soundName.toUpperCase());
					audioRef.current.src = doc.downloadURL;
				});
			});
			
		});		

	}, [soundIndex]);

	const checkGameState = () => {
		if (soundDoc === null) {
		  console.log("soundDoc is null");
		  return;
		}
	  
		try {
		  if (localStorage.getItem("firstGuess") !== "null") {
			handleGuess(localStorage.getItem("firstGuess"), 0);
			setTimeout(() => {
				if (localStorage.getItem("secondGuess") !== "null") {
					handleGuess(localStorage.getItem("secondGuess"), 1);
					setTimeout(() => {
					if (localStorage.getItem("thirdGuess") !== "null") {
						handleGuess(localStorage.getItem("thirdGuess"), 2);
					}
				}, 400);
				}
			}, 400);
			
		}		  
		} catch (error) {
		  console.error("Error checking guesses:", error);
		}
	  };

	const handleContinue = () => {
		setShowSplash(false); // Hide the splash page immediately
		// Wait for the splash page to be hidden before checking the game state
		localStorage.setItem("tempCount", localStorage.getItem("soundPlayCount"));
		setTimeout(() => {
			checkGameState(); 	// Check game state
		}, 100);
		setTimeout(() => {
			localStorage.setItem("soundPlayCount", localStorage.getItem("tempCount"));
			setPlayCount(parseInt(localStorage.getItem("soundPlayCount")));
		}, 100);
		if (localStorage.getItem("solvedToday") === "true") {
			//setPlayCount = -1000;
		}
	}

	const handlePlaySound = () => {
		if (!isButtonDisabled && playCount < 2) {
		  localStorage.setItem("playedToday", "true");
		  setIsButtonDisabled(true); // Disable the button
		  audioRef.current.pause();
		  audioRef.current.currentTime = 0;
		  audioRef.current.play();
	
		  setTimeout(() => {
			audioRef.current.pause();
			setIsButtonDisabled(false); // Enable the button after 2 seconds
		  }, 1210); // Stop playback after 2 seconds
	
		  setPlayCount(playCount + 1);
		  localStorage.setItem("soundPlayCount", playCount + 1);
		  localStorage.setItem("tempCount", playCount + 1);
		}
	  };

	  // add one to daily solves in firebase and return the current rank
	const updateRank = async () => {
		const rankDoc = await getDoc(doc(db, 'daily_solves', 'rank'));
		if (rankDoc.exists()) {
			let current_rank = rankDoc.data().current_rank;
			setPlayerRank(current_rank);
			const updated_rank = current_rank + 1;

			await setDoc(doc(db, 'daily_solves', 'rank'), {current_rank: updated_rank});

			return current_rank;
		} else {
			console.log("Document does not exist");
		}
	}

	//Show instructions
	const showInstructfunc = () => {
		if (showInstructions) {
			document.querySelector('.instruct-splash-content').classList.add('exit-animation');
			setTimeout(() => {
				setShowInstructions(false);
			}, 200);
		} else {
			setShowInstructions(true);
		}
	}

	//Show stats
	const handleStatsClick = () => {
		if (showStatsBox) {
			document.querySelector('.stats-splash-content').classList.add('exit-animation');
			setTimeout(() => {
				setShowStatsBox(false);
			}, 200);
		} else {
			setShowStatsBox(true);
		}
	}

	//Upload test data
	// const uploadTest = async () => {
	// 	//iterate through data.json and upload each file to firebase
	// 	const data = require("./data.json");
	// 	console.log("data: ", data);
	// 	for (let i = 0; i < data.length; i++) {
	// 		let soundName = data[i].soundName;
	// 		// remove the file extension and make it lower case
	// 		let docSoundName = soundName.split(".")[0].toLowerCase();
	// 		const soundCategory = data[i].soundCategory;
	// 		const answerKey = data[i].answerKey;
	// 		// Get the download URL of the file which has the name soundName
	// 		const soundsRef = ref(storageRef, `Sounds/${soundName}`);
	// 		getDownloadURL(soundsRef).then((url) => {
	// 			const downloadURL = url;
	// 			console.log("downloadURL: ", downloadURL);

	// 			// add the url to a json object and upload to firebase
	// 			const soundData = {
	// 				soundName: docSoundName,
	// 				soundCategory: soundCategory,
	// 				answerKey: answerKey,
	// 				downloadURL: downloadURL
	// 			}
	// 			console.log("soundData: ", soundData);
	// 			setDoc(doc(db, 'sounds', docSoundName), soundData).then(() => {
	// 				console.log("Document successfully written!");
	// 			});
	// 		});
	// 	}
	// };

	const handleGuess = async (word, guessCount = incorrectGuessCount) => {
		if (disableGuess) return;
	  
		localStorage.setItem("playedToday", "true");
		const isRight = await checkCorrectAnswer(soundDoc, word);

		try{
			if (isRight) {
				setCurrentSearch("");
				setDisableGuess(true); // Disable guessing after correct answer
				setAnswer(selectedSoundName); // Set the answer to display
				localStorage.setItem("answer", selectedSoundName);

				setPlayCount(-1000); // allow unlimited play

				//set timeout
				
				if (localStorage.getItem("solvedToday") === "false") { //if player has not solved before, add word to local storage
					addAnswer(word);
				}
				
				//set timeout
				setTimeout(() => {
					if (guessCount === 0) {
						setFirstBoxColor(correctGuessColor); // Set the first box color to green
					} else if (guessCount === 1) {
						setSecondBoxColor(correctGuessColor); // Set the second box color to green
					} else {
						setThirdBoxColor(correctGuessColor); // Set the third box color to green
					}

				}, 0);

				setTimeout(() => {
					setGameOver(true); // Set game over to true after correct answer
					setShowStatsBox(true);
				}, 620);
				localStorage.setItem("solvedToday", "true");
				

				if (localStorage.getItem("dailyRank") === "null") { //if player has not solved before, update rank.
					const playerRankNow = await updateRank();
						localStorage.setItem("dailyRank", playerRankNow);
					localStorage.setItem("dailyRank", playerRankNow);
					updateTotalSolves();
					logEvent(analytics, 'sound_solved');


					if (localStorage.getItem("totalSolves") === null) { //if player has solved before, increment total solves.
						localStorage.setItem("totalSolves", 1);
					} else {
						localStorage.setItem("totalSolves", parseInt(localStorage.getItem("totalSolves")) + 1);
					}
				} else {
				}
			} else {
				//window.alert("Incorrect!");
				setIncorrectGuessCount(guessCount + 1);
				setPlayCount(0);
				setCurrentSearch(""); //clears search box
				localStorage.setItem("soundPlayCount", "0");

			
				if (guessCount === 0) {
					if (category) {
					setDisplayedCategory(`Category: ${category}`);
					}
					localStorage.setItem("firstGuess", word);
					setTimeout(() => {
						setFirstBoxColor(wrongGuessColor); // Set the first box color to yellow
					}, 0);
				} else if(guessCount === 1) {
					setPlayCount(-1000); //unlimited play
					localStorage.setItem("secondGuess", word);
					setTimeout(() => {
					setSecondBoxColor(wrongGuessColor); // Set the second box color to yellow
					}, 0);
				} else {
					setDisableGuess(true); // Disable guessing after three incorrect attempts
					logEvent(analytics, 'sound_failed');

					setTimeout(() => {
						setShowStatsBox(true);
						setGameOver(true); // Set game over to true after 3 wrong guesses
					}, 500);
					setTimeout(() => {
						setShowAnswer(true); // Display the answer after 3 wrong guesses
					} , 500);
					setAnswer(selectedSoundName); // Set the answer to display
					localStorage.setItem("answer", selectedSoundName);
					setPlayCount(-1000); //unlimited play

					
					localStorage.setItem("thirdGuess", word);
					setTimeout(() => {
						setThirdBoxColor(wrongGuessColor); // Set the third box color to red
					}, 0);
					setTimeout(() => {
						setFirstBoxColor(allWrongColor); // Set the first box color to red
						setSecondBoxColor(allWrongColor); // Set the second box color to red
						setThirdBoxColor(allWrongColor); // Set the third box color to red
					}, 500);
				}
			}
		} catch (error) {
			clearStorage();
			console.log("Error: ", error);
		}
	  }

	if(!soundDoc) {
	return (
		<div className="loading-container">
			<div className="spinner"></div>
		</div>
	)
	} else {
	return (
		<div className="App">

			
			{/* <div>
				<button onClick={uploadTest}>
					Hi
				</button>
			</div> */}


	

			<header className="App-header">

				<TopBar onQuestionButtonClick={showInstructfunc} /> {/* Include TopBar */}

				{/* Instructions page */}
				{showInstructions && (
					<InstructionsSplash handleClickOutside={showInstructfunc}/>
				)}

				{/* Stats page */}
				{showStatsBox && <StatsSplash handleClickOutside={handleStatsClick} handlePlayClick={handlePlaySound}/>}

		
				<p className="title-text">CRINKLE</p>

				{/* Add guess boxes */}
				<div>
					<GuessResultGrid 
					color = {[firstBoxColor, secondBoxColor, thirdBoxColor]} 
					guess = {[localStorage.getItem("firstGuess"), localStorage.getItem("secondGuess"), localStorage.getItem("thirdGuess")]} />
				</div>
				
				<a href="#" className={`round-button ${(!isSoundPlayable || !isSoundPlayable) ? 'disabled-round-button' : ''}`} onClick={handlePlaySound} style={{ marginTop: '8px' }}>
					<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
						<i className="fa fa-play fa-2x"></i>
						<span style={{ whiteSpace: 'nowrap', margin: '0 auto' }}></span>
					</div>
				</a>
				<p style={{ margin: '8px 0' }}>{playsLeft > 5 ? 'Plays left: ∞' : `Plays left: ${playsLeft}`}</p>

				{/* Search results for phones */}
				{currentSearch ? ( 
					<div className="search-results-container-mobile">
						<ul >
						{guessList
						.filter((word) =>
							word.toLowerCase().includes(currentSearch.toLowerCase())
						)
						.map((word, index) => (
							<button
							className="guess-button"
							key={index}
							onClick={() => handleGuess(word)}
							disabled={disableGuess}
							>
							{word}
							</button>
						))}
					</ul>
					</div>
					) : <div style={{ overflowY: 'auto' }}>
					</div>
					}
				<div>
					<input
						className="input-box"
						value={currentSearch} // Ensure the value is set to the state
						onChange={(e) => setCurrentSearch(e.target.value)}
						placeholder="Search..."
						disabled={disableGuess} // Disable the input based on disableGuess
					/>
				</div>

				{gameOver && <button className="see-stats-button" onClick={handleStatsClick}>SEE STATS</button>}
				<div className="category-wrapper">
					{displayedCategory && localStorage.getItem("solvedToday") === "false" && !gameOver && (
						<p className="category-box">{displayedCategory}</p>
					)}
				</div>

				{showAnswer && <GuessResultBox color={correctGuessColor} guess={answer} />}

				{currentSearch ? (
					<div className="search-results-container">
						<ul >
						{guessList
						.filter((word) =>
							word.toLowerCase().includes(currentSearch.toLowerCase())
						)
						.map((word, index) => (
							<button
							className="guess-button"
							key={index}
							onClick={() => handleGuess(word)}
							disabled={disableGuess}
							>
							{word}
							</button>
						))}
					</ul>
					</div>
					) : <div style={{ overflowY: 'auto' }}>
					</div>
					}


				{/* Splash page */}
				{showSplash && (
					<ContinueSplash handleContinue={handleContinue} />
				)}

			</header>
		</div>
	);
	}
	
}

export default App;