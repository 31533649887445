import React from 'react';
import './App.js';
import { Grid, Button, TextField, Box} from '@mui/material'; // Import Material UI components
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import "./App.css";

//search box mui
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

// Custom styling for the text field
const CustomTextField = styled(TextField)({
    width: '30%', // Adjust width as needed
    margintop: '20px', // Add some margin at the top for spacing
    marginBottom: '20px', // Add some margin at the bottom for spacing
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'lightgray', // Border color when not focused
        backgroundColor: 'smoke', // Very light grey background
      },
      '&:hover fieldset': {
        borderColor: 'gray', // Border color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: 'lightblue', // Border color when focused
      },
      '& .MuiOutlinedInput-input': {
        color: 'whitesmoke', // Pastel black text color
      },
    },
  });
  
  const CustomizedTextField = () => {
    return (
      <CustomTextField
        label="Your Guess"
        variant="outlined"
        placeholder="Type your guess here..."
        // Add any additional props you need, such as onChange or value
      />
    );
  };
  
  export function BasicButton() {
    return (
        <Button variant="contained">Contained</Button>
    );
  }

  
 export const GuessResultBox = ({ color, guess }) => {
  return(
    <Box
      className={`guess-box ${color !== "grey" ? 'flipped' : ''}`}
							style={{
								backgroundColor: color,
								transition: 'background-color 0.7s ease-in-out' // Smooth transition over 0.5s
							  }}
                height= "100%"
                display="flex"
							alignItems="center"
							justifyContent="center"
							borderRadius={2}
							marginBottom={0}
							>
							{/* Only render the text if firstGuess is not null */}
							{guess !== "null" && <Typography variant="h6"
              sx={{ 
              fontWeight: 'bold', 
              fontSize: 'calc(10px + 2.5vmin)', 
              marginLeft: '-10px', 
              marginRight: '-10px',
              transition: 'opacity 1.2s',
              opacity: color !== "grey" ? 1 : 0,
              transform: 'rotateX(180deg)', // Flip the text upside down
              '@media (min-width: 600px)': {
                // Adjust the spacing for larger screens
                marginLeft: '-60px',
                marginRight: '-60px',
              },  
              }}>{guess.toUpperCase()}</Typography>}
		</Box>
    );
	}

  export const GuessResultGrid = ({ color, guess }) => {
    return (
        <Grid container direction="row" justifyContent="center">
          {color.map((color, index) => (
            <Grid item xs={8} sx={{minWidth: '360px', minHeight: '70px'}} textAlign="center" padding={1} key={index}>
              <GuessResultBox color={color} guess={guess[index]} />
            </Grid>
          ))}
        </Grid>
    );
  };

  export const TopBar = ({ onQuestionButtonClick }) => {
    return (
      <div className="top-bar">
        <div className="left-section">
          {/* Add your title or logo here */}
          <h1 className="title">Crinkle {'#' + localStorage.getItem("soundNumber")}</h1>
        </div>
        <div className="right-section" >
          {/* Question mark button */}
          <button className="question-button" onClick={onQuestionButtonClick} style={{ color: '#FFFF00'}}>
            <HelpOutlineIcon fontSize='large'/>
          </button>
          {/* BMC Button
          <a href="https://www.buymeacoffee.com/crinkle" target="_blank" rel="noreferrer" style={{ marginLeft: '8px', marginRight: '0px' }}>
            <img 
            src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" 
            alt="Buy Me A Coffee" 
            style={{ height: '35px', marginTop: '8px', marginRight: '-14px'}} 
            />
          </a> */}
        </div>
      </div>
    );
  };
  

 export default CustomizedTextField;