import { doc, getDoc } from "firebase/firestore";
import { db } from "./firebaseConfig";
import soundOrder from "./soundOrder.json";


export const getIndex = async () => { //this is the function that gets the index from the database
    try {
        const docSnap = await getDoc(doc(db, 'sound_order', 'count'));
        if (docSnap.exists()) {
          let current_index = docSnap.data().current_index;
          localStorage.setItem("soundNumber", current_index);
          return current_index;
        } else {
          console.log("Document does not exist");}
      } catch (error) {
        console.log("Error getting sound:", error);
      }
}

export const getSoundName = async (soundIndex) => { //this is the function that gets the sound name from the database
  const todaySound = soundOrder[soundIndex];
return todaySound;

}

export const checkCorrectAnswer = async (doc, guess) => {
  try {
    const isCorrect = doc.answerKey.some((answer) =>
      answer.trim().toLowerCase() === guess.trim().toLowerCase()
    );
    return isCorrect;
  } catch (error) {
    console.log("Error getting sound:", error);
    return false; // Return false in case of an error
  }
};

export const getDocument = async (soundName) => {
  try {
    const docSnap = await getDoc(doc(db, 'sounds', soundName));
    if (docSnap.exists()) {
      let soundDoc = docSnap.data();
      return soundDoc;
    } else {
    }
  } catch (error) {
  }
}