import { updateTotalPlays } from "./updateFunc";

export const addAnswer = (answer) => { //this function adds the answer to local storage
    if (localStorage.getItem("firstGuess") === "null") {
        localStorage.setItem("firstGuess", answer);
    } else if (localStorage.getItem("secondGuess") === "null") {
        localStorage.setItem("secondGuess", answer);
    } else if (localStorage.getItem("thirdGuess") === "null") {
        localStorage.setItem("thirdGuess", answer);
    }
}

export function getDateInPST() { //this function gets the date in PST and converts it to a string
    const date = new Date();
    // Get the UTC time
    const utcDate = date.getTime() + (date.getTimezoneOffset() * 60000);
    // Set the timezone offset for PST (-8 hours)
    const pstOffset = -8 * 60;
    // Calculate the PST time
    const pstDate = new Date(utcDate + (pstOffset * 60000));
    // Get the date part in PST
    const pstDateString = pstDate.toLocaleDateString('en-US', { timeZone: 'America/Los_Angeles' });
    // Return the date in PST
    return pstDateString;
}

export const dailyReset = () => { //this function resets the local storage for the day
    if (localStorage.getItem("todayDate") !== getDateInPST()) {
        updateTotalPlays(); //add to global total plays in firebase
        if (localStorage.getItem("totalPlays")) { //add to total plays in local storage
            localStorage.setItem("totalPlays", parseInt(localStorage.getItem("totalPlays")) + 1)
        }else {
            localStorage.setItem("totalPlays", "1");
        };
        localStorage.setItem("todayDate", getDateInPST());
        localStorage.setItem("soundPlayCount", "0");
        localStorage.setItem("firstGuess", "null");
        localStorage.setItem("secondGuess", "null");
        localStorage.setItem("thirdGuess", "null");
        localStorage.setItem("dailyRank", "null");
        localStorage.setItem("playedToday", "false");
        localStorage.setItem("solvedToday", "false");
        localStorage.setItem("answer", "null");
    }
}

export const clearStorage = () => { //this function clears the local storage
    const preservedScore = localStorage.getItem("totalSolves");
    const preservedPlays = localStorage.getItem("totalPlays");
    localStorage.clear();
    localStorage.setItem("totalSolves", preservedScore);
    localStorage.setItem("totalPlays", preservedPlays);
}

export const nullGuesses = () => { //this function sets the guesses in local storage to null
    localStorage.setItem("firstGuess", "null");
    localStorage.setItem("secondGuess", "null");
    localStorage.setItem("thirdGuess", "null");
}