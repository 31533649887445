import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_API_URL,
	projectId: "crinkle-2023",
	storageBucket: "crinkle-2023.appspot.com",
	messagingSenderId: process.env.REACT_APP_messaging_sender_id,
	appId: process.env.REACT_APP_app_id,
	measurementId: process.env.REACT_APP_measurement_id
  };


const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storageRef = getStorage(app);

export { app, db, storageRef};