import React, { useState } from "react";
import "./stats.css";
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';

import { getAnalytics, logEvent } from "firebase/analytics";
import { app } from "../firebaseConfig.js";

export const StatsSplash = ({handleClickOutside, handlePlayClick}) => {
  const analytics = getAnalytics(app);
  
  const [isCopied, setIsCopied] = useState(false);

  const yellowSquare = '🟨'; // Unicode character for a yellow square
  const greenSquare = '🟩'; // Unicode character for a green square
  const redSquare = '🟥'; // Unicode character for a red square

 let rank = "🟥";
 if(localStorage.getItem('solvedToday') === "true") {
   rank = localStorage.getItem('dailyRank');
  }
 let totalSolves = 0;
 if(localStorage.getItem('totalSolves')) {
   totalSolves = localStorage.getItem('totalSolves');
  }

  const handleShare = () => {
    handleCopy();
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500); // 2000 milliseconds = 2 seconds
  };

  const handleCopy = () => {
    let copyString;
    if(localStorage.getItem('solvedToday') === "false") {
      copyString = `${redSquare}${redSquare}${redSquare}`;
    } else if (localStorage.getItem('secondGuess') === "null") {
        copyString = `${greenSquare}${greenSquare}${greenSquare}`;
      } else if (localStorage.getItem('thirdGuess') === "null") {
          copyString = `${yellowSquare}${greenSquare}${greenSquare}`;
        } else {
            copyString = `${yellowSquare}${yellowSquare}${greenSquare}`;
          }    
    navigator.clipboard.writeText('My crinkle #' + localStorage.getItem("soundNumber") + ' score was ' + copyString + "! Play Crinkle at crinkle.us");
  };

  const LinkButton = ({ url, label }) => {
    const handleClick = () => {
      window.open(url, '_blank');
      logEvent(analytics, 'calendar_added')
    };
  
    return (
      <Button variant="contained" onClick={handleClick}>
        {label}
      </Button>
    );
  };
  

  return (
    <div className="stats-splash-page" >
      < div className = "stats-background" onClick = {handleClickOutside} > </div>

      <div className="stats-splash-content">
          <button className='close-button' onClick={handleClickOutside}>
            <CloseIcon fontSize='medium'/>
          </button>
        <h1 className='stats-heading'>STATS</h1>

        <div className="stats-container">
          <div className="individual-stats-container">
            <p className='stats-subheading'>Total Plays</p>
            <p className='stats-counts'>{localStorage.getItem('totalPlays')}</p>
          </div>
          <div className="individual-stats-container">
            <p className='stats-subheading'>Total Solves</p>
            <p className='stats-counts'>{totalSolves}</p>
          </div>
        </div>

        <div className="stats-2-container">
          <div className="individual-stats-container">
              <p className='stats-subheading'>Daily Rank</p>
              <p className='stats-counts'>{rank}</p>
          </div>
        </div>

        <p className='stats-paragraph'>If you like it, share it around!</p>


        <hr />

        <div className="guess-stats-container">
          <p className='guess-stats-subheading'>{localStorage.getItem("answer")}</p>
        </div>

        <div className="stats-play-container">
          <a href="#" className={ "round-button" } onClick={handlePlayClick} style={{ marginTop: '8px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <i className="fa fa-play fa-2x"></i>
              <span style={{ whiteSpace: 'nowrap', margin: '0 auto' }}></span>
            </div>
          </a>
        </div>

        <hr />

        <div className="stats-button-container">
          <button 
            className={`share-button ${(!isCopied || !isCopied) ? 'disabled-share-button' : ''}`} 
            onClick={handleShare}
            disabled={isCopied}
          >
            {isCopied ? 'Copied!' : 'SHARE'}
          </button>
          {/* <a 
            href="https://www.buymeacoffee.com/crinkle" 
            target="_blank" 
            rel="noreferrer" 
            style={{ marginLeft: '8px', marginRight: '0px' }}
          >
            <img 
              src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" 
              alt="Buy Me A Coffee" 
              style={{ height: '38px', marginTop: '7px', borderRadius: '7px', border: '1px solid #7F0000'}} 
            />
          </a> */}
        </div>

        <hr />
        
        <p className='stats-paragraph'>Come back tomorrow for a new sound!</p>
        <div className='calendar-button-container'>
          <LinkButton url="https://calendar.google.com/calendar/event?action=TEMPLATE&tmeid=M2JmOWczZ3I0YWQwb2Y5MDFsZWNvMzJuOHNfMjAyNDAyMjlUMDIwMDAwWiA5MWZhYjc3ZmEwZTM0NDlhZTE2N2QzMjk5NjVkMDAwMjYxZGUxMGM5NTU3OTBlYTNkMGY2YzFjNzNmMGJlMjYwQGc&tmsrc=91fab77fa0e3449ae167d329965d000261de10c955790ea3d0f6c1c73f0be260%40group.calendar.google.com&scp=ALL" label="Add to Google Calendar" />
        </div>        
      </div>
    </div>

  );
};