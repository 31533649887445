import { setDoc, doc, getDoc } from "firebase/firestore";
import { db } from "./firebaseConfig";

//add to total plays in firebase
const updateTotalPlays = async () => {
    const total_playsDoc = await getDoc(doc(db, 'play_stats', 'total_plays'));
    if (total_playsDoc.exists()) {
        let current_total_plays = total_playsDoc.data().play_count;
        const updated_play_count = current_total_plays + 1;
        await setDoc(doc(db, 'play_stats', 'total_plays'), {play_count: updated_play_count});
    }
}

//add to total solves in firebase
const updateTotalSolves = async () => {
    const total_solvesDoc = await getDoc(doc(db, 'play_stats', 'total_solves'));
    if (total_solvesDoc.exists()) {
        let current_solve_count = total_solvesDoc.data().solve_count;
        const updated_solve_count = current_solve_count + 1;
        await setDoc(doc(db, 'play_stats', 'total_solves'), {solve_count: updated_solve_count});
    }
}

export { updateTotalPlays, updateTotalSolves };